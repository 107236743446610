import React from 'react';
import { Content, Heading, Page, PageTitle, Paragraph, TitledParagraph, List, Indent } from 'pages/Report/components/Styled';

export const UninsuredRisks = ({ formName, reportState }) => {
  const { recommendedCovers = [], importantItemsToConsider = [], businessPackRecommendedCovers = [] } = reportState;

  const StrataUninsuredRisks = () => (
    <Page dynamic formName={formName}>
      <PageTitle>Recommendations</PageTitle>
      <Content>
        {recommendedCovers?.length > 0 && (
          <>
            <Heading level={2}>Uninsured Risks</Heading>
            {recommendedCovers?.includes('OfficeBearersLiability') && (
              <TitledParagraph>
                <Heading level={3}>Office Bearer&apos;s Liability</Heading>
                <Paragraph>
                  We recommend your Corporation insures for Office Bearer&apos;s Liability with a sum insured of at least $1,000,000. This
                  covers the Officers of the Corporation listed on the Schedule for amounts they become legally liable to pay arising from a
                  Claim in connection with managing the affairs of the Body Corporate.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('CatastropheCover') && (
              <TitledParagraph>
                <Heading level={3}>Catastrophe Cover</Heading>
                <Paragraph>
                  We recommend your Corporation insures for Catastrophe Cover as an optional extra. This will increase your Sums Insured by
                  up to 30% when a State of Emergency is declared following an insurable event. In the aftermath of a State of Emergency,
                  the surge in demand can increase the costs of labour, materials and accommodation significantly.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('FloodCover') && (
              <TitledParagraph>
                <Heading level={3}>Flood Cover</Heading>
                <Paragraph>
                  We recommend your Corporation insures for Flood Cover as an optional extra. Due to the climate and conditions in the NT,
                  flooding can be quite a large exposure.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('MachineryBreakdown') && (
              <TitledParagraph>
                <Heading level={3}>Machinery Breakdown</Heading>
                <Paragraph>
                  We recommend your Corporation insures for Machinery Breakdown Cover as an optional extra. This provides cover against
                  breakdown of air conditioning units and any other electrical, electronic and mechanical plant (elevators, pool pumps,
                  switchboards etc.) at the premises.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('VoluntaryWorkers') && (
              <TitledParagraph>
                <Heading level={3}>Voluntary Workers</Heading>
                <Paragraph>
                  We recommend your Corporation insures for Voluntary Workers. This provides cover for injury to Voluntary Workers who
                  undertake unpaid work at the corporations direction on the property.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('StormSurge') && (
              <TitledParagraph>
                <Heading level={3}>Storm Surge</Heading>
                <Paragraph>
                  We recommend your Corporation insures for Storm Surge. This provides cover for abnormal increases in sea levels,
                  associated with a tropical cyclone or intense storm.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('LossOfRentTemporaryAccommodation') && (
              <TitledParagraph>
                <Heading level={3}>Loss of Rent/Temporary Accommodation</Heading>
                <Paragraph>
                  We recommend your Corporation insures for Loss of Rent/Temporary Accommodation, as this will provide cover for the Unit
                  Owner(s) in the event that the property is rendered uninhabitable, due to an insurable event.
                </Paragraph>
                <Paragraph>
                  If the property is Owner Occupied, then the Insurer will pay the reasonable costs of Temporary Accommodation for the
                  period that the Unit is uninhabitable.
                </Paragraph>
                <Paragraph>
                  If the property is Tenanted, then the Insurer will pay the Unit Owner(s) for the amount of Rent that is lost for the
                  period that the Unit is uninhabitable.
                </Paragraph>
                <Paragraph>Please note Tenants are not insured for Temporary Accommodation under the policy.</Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('FidelityGuarantee') && (
              <TitledParagraph>
                <Heading level={3}>Fidelity Guarantee</Heading>
                <Paragraph>
                  We recommend that your Corporation insures for Fidelity, as this provides cover for Body Corporate funds as a direct
                  result of theft, embezzlement, fraudulent misappropriation, conversion or fraud that occurs during the Period of
                  Insurance.
                </Paragraph>
              </TitledParagraph>
            )}
          </>
        )}
        {importantItemsToConsider?.length > 0 && (
          <>
            <Heading level={2}>IMPORTANT ITEMS TO CONSIDER</Heading>
            {importantItemsToConsider?.includes('BuildingValuations') && (
              <TitledParagraph>
                <Heading level={3}>Building Valuations</Heading>
                <Paragraph>
                  It is important that an Insurance Building Valuation or Building Sum Insured Review be conducted on a regular basis. This
                  will provide your Corporation with professional advice to ensure you are adequately covered.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('RoofInspections') && (
              <TitledParagraph>
                <Heading level={3}>Roof Inspections</Heading>
                <Paragraph>
                  Due to the significant rainfall we receive, it is important to ensure regular roof inspections are completed. A well
                  maintained roof will assist in reduced claims and in turn keep premiums increases to a minimum.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('FireSafetyEquipment') && (
              <TitledParagraph>
                <Heading level={3}>Fire Safety Equipment</Heading>
                <Paragraph>
                  It is important that fire safety equipment is regularly inspected and follows all relevant legislation. Speak to your Body
                  Corporate Manager to ensure your Corporation is up to code as required.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('LeakDetection') && (
              <TitledParagraph>
                <Heading level={3}>Leak Detection</Heading>
                <Paragraph>
                  If your Corporation experiences high water charges or ongoing leaks, it is recommended you engage a plumber to inspect the
                  property.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('CyclonePreparedness') && (
              <TitledParagraph>
                <Heading level={3}>Cyclone Preparedness</Heading>
                <Paragraph>
                  It is important that your Corporation has a cyclone plan in place. The Northern Territory Government as well as the
                  Northern Territory Police, Fire and Emergency Services have action plans and guides available on their respective
                  websites.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('PreventativeMaintenance') && (
              <TitledParagraph>
                <Heading level={3}>Preventative Maintenance</Heading>
                <Paragraph>
                  It is important for your Corporation to undertake regular inspections of the exterior, interior and roof spaces to ensure
                  the property is watertight and sound. A well maintained property can alleviate potential claims before they arise, saving
                  your Corporation both time and money. This is likely to reduce claims occurrence and alleviate premium increases or
                  remedial action such as imposed excesses or declining to continue cover in the future.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('OfficeBearersLiability') && (
              <TitledParagraph>
                <Heading level={3}> Office Bearer&apos;s Liability</Heading>
                <Paragraph>
                  We recommend your Corporation considers increasing the Sum Insured for Office Bearer’s Liability to at least $1,000,000.
                  This covers the Officers of the listed Corporation on the Schedule for amounts they become legally liable to pay, arising
                  from a Claim in connection with managing the affairs of the Body Corporate.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('Pre1985') && (
              <TitledParagraph>
                <Heading level={3}>Pre 1985</Heading>
                <Paragraph>
                  In some instances, Insurers have begun imposing special conditions or excesses on policies for properties built prior to
                  1985 that have incurred claims. We recommend that the insured have the plumbing and/or electrical checked/surveyed and
                  subsequent actions attended to mitigate any future loss or conditions or excesses that may be imposed to the policy.
                </Paragraph>
              </TitledParagraph>
            )}
          </>
        )}
      </Content>
    </Page>
  );

  const WorkerCompensationUninsuredRisks = () => (
    <>
      {importantItemsToConsider?.length > 0 && (
        <Page dynamic formName={formName}>
          <PageTitle>Important Items to Consider </PageTitle>
          <Content>
            {importantItemsToConsider?.includes('ClaimsTimeline') && (
              <TitledParagraph>
                <Heading level={3}>Claims Timeline</Heading>
                <List>
                  <li>
                    Please obtain a claim or incident notification form from the NT Worksafe website: <br />
                    <a href="https://worksafe.nt.gov.au/workers-compensation" rel="noreferrer" target="_blank">
                      https://worksafe.nt.gov.au/workers-compensation
                    </a>
                  </li>
                  <li>You cannot refuse an Employee wanting to submit a claim</li>
                  <li>The Employee must complete their section/s first</li>
                  <li>The Employer must complete the remainder of the form</li>
                  <li>
                    As soon as the claim form has been signed by the Employee, the claim form MUST be submitted to MGA within THREE (3)
                    working days
                  </li>
                  <li>
                    The Insurer has 10 working days to determine liability, at which point they can defer for up to 56 days to further
                    determine liability
                  </li>
                </List>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('DeclaringWages') && (
              <TitledParagraph>
                <Heading level={3}>Declaring Wages</Heading>
                <Paragraph>
                  When declaring or estimating wages you are required to include tax, however superannuation payments do not need to be
                  declared. Additionally, if the company is Proprietary Limited, you can opt to include cover for Working Directors. In
                  order for Working Directors to qualify, correct tax needs to be deducted from the income received.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('QuarterlyPayments') && (
              <TitledParagraph>
                <Heading level={3}>Quarterly Payments</Heading>
                <Paragraph>
                  As per the legislation for Workers Compensation in the NT, insurers offer payment via four instalments at no additional
                  cost to your business.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('Contractors') && (
              <TitledParagraph>
                <Heading level={3}>Contractors</Heading>
                <Paragraph>
                  Please check the ATO website below to see whether contractors may be considered an employee. If the majority of their work
                  performed is for your business then they may be considered as an employee for the purpose of Workers Compensation.
                </Paragraph>
              </TitledParagraph>
            )}
          </Content>
        </Page>
      )}
      {recommendedCovers?.length > 0 && (
        <Page dynamic formName={formName}>
          <PageTitle>Recommendations</PageTitle>
          <Content>
            {recommendedCovers?.includes('PreemploymentCheck') && (
              <TitledParagraph>
                <Heading level={3}>Pre-employment Checks</Heading>
                <Paragraph>
                  The NT Workers Compensation uses the &apos;Return to Work Act 1986&apos;, this is a &apos;no fault scheme&apos;, meaning
                  that when you employ a person you take on all pre-existing injuries and conditions. It is recommended to minimize any
                  potential claims that you implement pre-employment checks, noting that even if this is done and a pre-existing injury
                  re-occurs, it could still be covered your Workers Compensation policy.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('WHSIncidentReporting') && (
              <TitledParagraph>
                <Heading level={3}>Work Health and Safety (WHS) Manuals and Incident Reporting</Heading>
                <Paragraph>
                  Implementing and utilising WHS Manuals and Incident Reporting Systems will not only enhance workplace safety, it can also
                  assist with premium negotiations at renewal. Insurers may take this into consideration as they can see that potential
                  hazards have been identified and are being mitigated and there is a governance structure in place
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('ReturnToWorkPlan') && (
              <TitledParagraph>
                <Heading level={3}>Return to Work Plan</Heading>
                <Paragraph>
                  Your business must have a documented Return to Work Plan in place in the event an employee is injured and cannot return to
                  work immediately. Following a Workers Compensation claim, all employers are required to submit a Return to Work proposal
                  within seven (7) days after they become aware of the injured worker&apos;s total or partial incapacity that is likely to
                  exceed 28 days. A Return to Work Plan is a tool for employers to proactively help injured workers safely return to work as
                  soon as is practicable. Please refer to the NT Worksafe website for additional information:{' '}
                  <a href="https://worksafe.nt.gov.au/workers-compensation" rel="noreferrer" target="_blank">
                    https://worksafe.nt.gov.au/workers-compensation
                  </a>
                </Paragraph>
              </TitledParagraph>
            )}

            {recommendedCovers?.includes('CommonDueDating') && (
              <TitledParagraph>
                <Heading level={3}>Common Due Dating</Heading>
                <Paragraph>
                  It is our recommendation that the Workers Compensation policy is aligned to expire with all other policies that you
                  currently have in place. This will minimize the amount of administration you spend on insurance throughout the year and
                  ensures a holistic approach to managing your risk.
                </Paragraph>
              </TitledParagraph>
            )}
          </Content>
        </Page>
      )}
    </>
  );

  const CommercialMotorUninsuredRisks = () => (
    <>
      {recommendedCovers?.length > 0 && (
        <Page dynamic formName={formName}>
          <PageTitle>Recommendations</PageTitle>

          <Content>
            <Heading level={2}>You are NOT currently insured for;</Heading>
            {recommendedCovers?.includes('LossOfUse') && (
              <TitledParagraph>
                <Heading level={3}>Loss of Use</Heading>
                <Paragraph>
                  This optional benefit provides coverage that reimburses you for extra expenses you incur because you cannot make use of
                  your property due to an insured event. Additionally, this benefit covers you for loss of income whilst your vehicle is not
                  operational as a result of an insured event.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('HireCarFollowingAccident') && (
              <TitledParagraph>
                <Heading level={3}>Hire Car Following an Accident</Heading>
                <Paragraph>
                  This optional benefit gives you the option to hire a like for like vehicle whilst your vehicle is undergoing repair.
                  Please note this is applicable to business sedans, utes and wagons and may not be available for larger vehicles or trucks.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('HiredInPlantAndEquipment') && (
              <TitledParagraph>
                <Heading level={3}>Hired in Plant and Equipment</Heading>
                <Paragraph>
                  Hired in Plant and Equipment provides you with insurance cover for any hired in plant and equipment the business may use.
                  This is a blanket cover saving you the hassle of paying for add-on insurance each and every time you hire plant or
                  equipment. For example, Kennards or Darwin Hire.
                </Paragraph>
              </TitledParagraph>
            )}
          </Content>
        </Page>
      )}
      {importantItemsToConsider?.length > 0 && (
        <Page dynamic formName={formName}>
          <PageTitle>Important Items to Consider </PageTitle>
          <Content>
            {importantItemsToConsider?.includes('AccessoriesAndModifications') && (
              <TitledParagraph>
                <Heading level={3}>Accessories and Modifications</Heading>
                <Paragraph>
                  It is important to list all after-market accessories and modifications to your vehicle. In a total loss event, the costs
                  of your accessories and modifications will not be taken into consideration unless listed on the schedule resulting in a
                  lower settlement.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('AdditionalExcessesApplicable') && (
              <TitledParagraph>
                <Heading level={3}>Additional Excesses Applicable</Heading>
                <Paragraph>
                  There may be additional excess applicable for young or inexperienced drivers. These excess are payable in addition to the
                  basic excess if the young or inexperienced driver has an at fault claim.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('OwnVehicleDamage') && (
              <TitledParagraph>
                <Heading level={3}>Own Vehicle Damage</Heading>
                <Paragraph>
                  It is important to remember that if you have an accident with two of your own vehicles with no other party involved, you
                  will have to pay an excess for each vehicle.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('RadiusLimit') && (
              <TitledParagraph>
                <Heading level={3}>Radius Limit</Heading>
                <Paragraph>
                  You can allocate a radius limit from home base for vehicle usage. This can reduce premiums as the vehicles are not
                  travelling remotely. Please note the vehicles are still insured if they exceed the radius limit however in the event of a
                  claim, the applicable excess is doubled.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('VehicleSumsInsured') && (
              <TitledParagraph>
                <Heading level={3}>Vehicle Sums Insured</Heading>
                <Paragraph>
                  It is important to keep up to date with current market values in relation to heavier vehicles, especially in the ever
                  changing market. Insurers will price your heavier vehicles based on sums insured so a regular review is required.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('FleetListings') && (
              <TitledParagraph>
                <Heading level={3}>Fleet Listings</Heading>
                <Paragraph>
                  Up to date Fleet and/or Plant Listings are important in relation to potential claims. If your fleet listing is not up
                  date, items may by missed on policies and subsequently not insured.
                </Paragraph>
              </TitledParagraph>
            )}
          </Content>
        </Page>
      )}
    </>
  );

  const MasterRenewalUninsuredRisks = () => (
    <>
      {(!!recommendedCovers?.length || !!businessPackRecommendedCovers.length) && (
        <Page dynamic formName={formName}>
          <PageTitle>Recommendations AND / OR UNINSURED RISKS</PageTitle>
          <Content>
            {!!businessPackRecommendedCovers.length && (
              <>
                <Heading level={2}>Business Pack</Heading>
                <Indent>
                  {businessPackRecommendedCovers?.includes('Building') && (
                    <TitledParagraph>
                      <Heading level={3}>Building</Heading>
                      <Paragraph>
                        If there are any Buildings at your business premises or situation listed, you may want to consider including them as
                        part of the policy. This includes cover against perils such as Fire, Storm, Water and Lightning among others.
                        Accidental Damage is an available option.
                      </Paragraph>
                    </TitledParagraph>
                  )}
                  {businessPackRecommendedCovers?.includes('ContentsStock') && (
                    <TitledParagraph>
                      <Heading level={3}>Contents / Stock</Heading>
                      <Paragraph>
                        This provides cover for Contents and/or Stock that is situated at the premises listed on the schedule or coverage
                        summary. It covers insured events such as Fire, Storm, Water and Lightning among others. Accidental Damage is an
                        available option.
                      </Paragraph>
                    </TitledParagraph>
                  )}
                  {businessPackRecommendedCovers?.includes('Theft') && (
                    <TitledParagraph>
                      <Heading level={3}>Theft</Heading>
                      <Paragraph>
                        Contents and Stock can be insured against Theft while they are at the situation/s declared on the policy schedule or
                        coverage summary. Please ensure items are securely stored and locked away when not in use as Theft in the Open Air
                        is often excluded unless specifically added.
                      </Paragraph>
                    </TitledParagraph>
                  )}
                  {businessPackRecommendedCovers?.includes('Glass') && (
                    <TitledParagraph>
                      <Heading level={3}>Glass</Heading>
                      <Paragraph>
                        If there are any windows, glass doors or internal glass at the situation you may want to consider adding this
                        section. It is usually the responsibility of the tenant as part of the lease agreement to have Glass insured.
                      </Paragraph>
                    </TitledParagraph>
                  )}
                  {businessPackRecommendedCovers?.includes('Money') && (
                    <TitledParagraph>
                      <Heading level={3}>Money</Heading>
                      <Paragraph>
                        If cash is kept onsite at the situation listed, this section can provide protection against theft of Money including
                        whilst in transit, at home, in a safe and at the situation listed during business hours.
                      </Paragraph>
                    </TitledParagraph>
                  )}
                  {businessPackRecommendedCovers?.includes('Liability') && (
                    <TitledParagraph>
                      <Heading level={3}>Liability</Heading>
                      <Paragraph>
                        This section provides cover for Public and Products Liability up to the limit selected against Third Party Personal
                        Injury and Property Damage. It is a basic requirement under most contracts or tenders that you hold Public
                        Liability.
                      </Paragraph>
                      <Paragraph>
                        Additional covers such as Errors and Omissions and Faulty Workmanship can be considered by the insurer if your
                        occupation has an exposure for these.
                      </Paragraph>
                    </TitledParagraph>
                  )}
                  {businessPackRecommendedCovers?.includes('ElectronicEquipment') && (
                    <TitledParagraph>
                      <Heading level={3}>Electronic Equipment</Heading>
                      <Paragraph>
                        This provides cover for breakdown of electronic items, not caused by an insured peril. Please note, this section
                        does not provide cover where the item has failed due to deterioration, wear and tear or old age. Lightning damage is
                        covered under the Contents and Stock section automatically.
                      </Paragraph>
                    </TitledParagraph>
                  )}
                  {businessPackRecommendedCovers?.includes('MachineryBreakdown') && (
                    <TitledParagraph>
                      <Heading level={3}>Machinery Breakdown</Heading>
                      <Paragraph>
                        This provides cover for breakdown of machinery items, not caused by an insured peril. Please note, this section does
                        not provide cover where the item has failed due to deterioration, wear and tear or old age. Lightning damage is
                        covered under the Contents and Stock section automatically.
                      </Paragraph>
                    </TitledParagraph>
                  )}
                  {businessPackRecommendedCovers?.includes('BusinessInterruption') && (
                    <TitledParagraph>
                      <Heading level={3}>Business Interruption</Heading>
                      <Paragraph>
                        <List>
                          <li>
                            Loss of Income - If there is an insured event that does not allow you to operate your business as you normally
                            would and as a result there is a Loss of Income, this section would respond and a claim could be made.
                          </li>
                          <li>
                            Additional Increased Cost of Working - This sub-limit under Business Interruption can be taken in conjunction
                            with Loss of Income or as a standalone cover. This provides cover for any unforeseen/additional costs that the
                            business may incur as a result of an insured event to get the business trading as quickly as possible, including
                            relocation, hiring costs or the like.
                          </li>
                          <li>
                            Payroll/Wages - In the event of an insured event that causes your business to temporarily cease trading, you may
                            need to retain key staff and continue paying them as you would normally. This section can assist in covering the
                            wages of key employees.
                          </li>
                        </List>
                      </Paragraph>
                    </TitledParagraph>
                  )}
                  {businessPackRecommendedCovers?.includes('PortableProperty') && (
                    <TitledParagraph>
                      <Heading level={3}>Portable Property</Heading>
                      <Paragraph>
                        Tools and Portable Electronic Equipment are a valuable asset to any business. This section covers against Accidental
                        Loss or Damage, Theft and other insured perils. In order for theft to be covered, all items need to be securely
                        stored when not in use and forcible entry needs to be proven for a claim to be considered.
                      </Paragraph>
                    </TitledParagraph>
                  )}
                </Indent>
              </>
            )}
            {recommendedCovers?.includes('PortableProperty') && !businessPackRecommendedCovers?.includes('PortableProperty') && (
              <TitledParagraph>
                <Heading level={3}>Portable Property</Heading>
                <Paragraph>
                  Tools and Portable Electronic Equipment are a valuable asset to any business. This section covers against Accidental Loss
                  or Damage, Theft and other insured perils. In order for theft to be covered, all items need to be securely stored when not
                  in use and forcible entry needs to be proven for a claim to be considered.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('MachineryBreakdown') && !businessPackRecommendedCovers?.includes('MachineryBreakdown') && (
              <TitledParagraph>
                <Heading level={3}>Machinery Breakdown</Heading>
                <Paragraph>
                  This provides cover for breakdown of machinery items, not caused by an insured peril. Please note, this section does not
                  provide cover where the item has failed due to deterioration, wear and tear or old age. Lightning damage is covered under
                  the Contents and Stock section automatically.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('ElectronicEquipment') && !businessPackRecommendedCovers?.includes('ElectronicEquipment') && (
              <TitledParagraph>
                <Heading level={3}>Electronic Equipment</Heading>
                <Paragraph>
                  This provides cover for breakdown of electronic items, not caused by an insured peril. Please note, this section does not
                  provide cover where the item has failed due to deterioration, wear and tear or old age. Lightning damage is covered under
                  the Contents and Stock section automatically.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('MarineTransit') && !businessPackRecommendedCovers?.includes('MarineTransit') && (
              <TitledParagraph>
                <Heading level={3}>Transit (via Road, Rail, Sea, Air)</Heading>
                <Paragraph>
                  If you are a carrying your own, customer or supplier goods, you may need to consider a Marine (via Road, Rail, Air or Sea)
                  Transit policy. There are different types available, which can provide cover for Accidental Damage, Fire, Collision,
                  Overturning of the Conveying Vehicle, or purely Carriers Liability.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('Liability') && !businessPackRecommendedCovers?.includes('Liability') && (
              <TitledParagraph>
                <Heading level={3}>Liability</Heading>
                <Paragraph>
                  This section provides cover for Public and Products Liability up to the limit selected against Third Party Personal Injury
                  and Property Damage. It is a basic requirement under most contracts or tenders that you hold Public Liability.
                </Paragraph>
                <Paragraph>
                  Additional covers such as Errors and Omissions and Faulty Workmanship can be considered by the insurer if your occupation
                  has an exposure for these.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('ProfessionalIndemnity') && !businessPackRecommendedCovers?.includes('ProfessionalIndemnity') && (
              <TitledParagraph>
                <Heading level={3}>Professional Indemnity</Heading>
                <Paragraph>
                  If you provide a professional service for a fee, you likely require Professional Indemnity Insurance. It provides cover
                  for the financial consequences of negligence, error or omission by the professional or firm.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('ManagementLiability') && !businessPackRecommendedCovers?.includes('ManagementLiability') && (
              <TitledParagraph>
                <Heading level={3}>Management Liability</Heading>
                <Paragraph>
                  This covers any claims made against the directors or the company allegedly having not met their duties in the day to day
                  operation of the business. Cover is included for employment practices, bullying, harassment, discrimination and unfair
                  dismissal suits. It also provides cover for defence and legal costs, fines and penalties incurred, Work Health and Safety
                  and <strong>Tax Audit</strong>, among other things.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('Cyber') && !businessPackRecommendedCovers?.includes('Cyber') && (
              <TitledParagraph>
                <Heading level={3}>Cyber</Heading>
                <Paragraph>
                  This policy is designed to protect your business against cyber criminals. A typical Cyber policy includes the following
                  covers:
                  <List>
                    <li>
                      First party losses, for example a loss to you or your business, such as;
                      <List>
                        <li>IT forensic costs</li>
                        <li>Data recovery costs</li>
                        <li>Cyber extortion costs (including ransom demands from hackers)</li>
                        <li>Credit monitoring costs</li>
                        <li>Legal representation costs</li>
                      </List>
                    </li>
                    <li>
                      Third party losses, for example a cyber incident within your business affecting your clients, suppliers or other
                      associated entity;
                      <List>
                        <li>Claims for compensation</li>
                        <li>Legal and defence costs</li>
                        <li>Costs arising from investigations from a government regulator</li>
                        <li>Fines and penalties for breaches of the Privacy Act</li>
                      </List>
                    </li>
                    <li>
                      Business Interruption as a result of a cyber incident
                      <List>
                        <li>Loss of income and expense reimbursement</li>
                        <li>Loss of income and expense reimbursement</li>
                      </List>
                    </li>
                  </List>
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('CorporateTravel') && !businessPackRecommendedCovers?.includes('CorporateTravel') && (
              <TitledParagraph>
                <Heading level={3}>Corporate Travel</Heading>
                <Paragraph>
                  Cover extends to business and leisure travel for all directors and employees of the company. Directors also benefit from
                  cover extending to their spouse and dependents when travelling for leisure. The policy includes cover for medical
                  expenses, cancellation of accommodation and flights, lost baggage, death and funeral expenses, among many others.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('CommercialMotor') && !businessPackRecommendedCovers?.includes('CommercialMotor') && (
              <TitledParagraph>
                <Heading level={3}>Commercial Motor</Heading>
                <Paragraph>
                  <List>
                    <li>
                      Loss of Use - This optional benefit provides coverage that reimburses you for extra expenses you incur because you
                      cannot make use of your property due to an insured event. Additionally, this benefit covers you for loss of income
                      whilst your vehicle is not operational as a result of an insured event.
                    </li>
                    <li>
                      Hire Car Following an Accident - This optional benefit gives you the option to hire a like for like vehicle whilst
                      your vehicle is undergoing repair. Please note this is applicable to business sedans, utes and wagons and may not be
                      available for larger vehicles or trucks.
                    </li>
                    <li>
                      Hired in Plant and Equipment - Hired in Plant and Equipment provides you with insurance cover for any hired in plant
                      and equipment the business may use. This is a blanket cover saving you the hassle of paying for add-on insurance each
                      and every time you hire plant or equipment. For example, Kennards or Darwin Hire.
                    </li>
                  </List>
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('WorkersCompensation') && !businessPackRecommendedCovers?.includes('WorkersCompensation') && (
              <>
                <TitledParagraph>
                  <Heading level={3}>Worker&apos;s Compensation</Heading>
                  <Paragraph>
                    <List>
                      <li>
                        Pre-employment Checks - The NT Workers Compensation uses the &apos;Return to Work Act 1986&apos;, this is a &apos;no
                        fault scheme&apos;, meaning that when you employ a person you take on all pre-existing injuries and conditions. It
                        is recommended to minimize any potential claims that you implement pre-employment checks, noting that even if this
                        is done and a pre-existing injury re-occurs, it could still be covered your Workers Compensation policy.
                      </li>
                      <li>
                        Work Health and Safety (WHS) Manuals and Incident Reporting - Implementing and utilising WHS Manuals and Incident
                        Reporting Systems will not only enhance workplace safety, it can also assist with premium negotiations at renewal.
                        Insurers may take this into consideration as they can see that potential hazards have been identified and are being
                        mitigated and there is a governance structure in place
                      </li>
                      <li>
                        Return to Work Plan - Your business must have a documented Return to Work Plan in place in the event an employee is
                        injured and cannot return to work immediately. Following a Workers Compensation claim, all employers are required to
                        submit a Return to Work proposal within seven (7) days after they become aware of the injured worker&apos;s total or
                        partial incapacity that is likely to exceed 28 days. A Return to Work Plan is a tool for employers to proactively
                        help injured workers safely return to work as soon as is practicable. Please refer to the NT Worksafe website for
                        additional information:{' '}
                        <a href="https://worksafe.nt.gov.au/workers-compensation" rel="noreferrer" target="_blank">
                          https://worksafe.nt.gov.au/workers-compensation
                        </a>
                      </li>
                      <li>
                        Common Due Dating - It is our recommendation that the Workers Compensation policy is aligned to expire with all
                        other policies that you currently have in place. This will minimize the amount of administration you spend on
                        insurance throughout the year and ensures a holistic approach to managing your risk.
                      </li>
                    </List>
                  </Paragraph>
                </TitledParagraph>
              </>
            )}
            {recommendedCovers?.includes('VoluntaryWorkers') && !businessPackRecommendedCovers?.includes('VoluntaryWorkers') && (
              <TitledParagraph>
                <Heading level={3}>Voluntary Workers</Heading>
                <Paragraph>
                  If your company often relies upon or engages volunteers to assist, cover would not be afforded under Workers Compensation
                  as there are no wages being paid. Voluntary Workers can provide cover for medical costs, loss of income as well as other
                  sections, to a party that has volunteered their time and is injured during the course of their duties with your company.{' '}
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('AssociationLiability') && !businessPackRecommendedCovers?.includes('AssociationLiability') && (
              <TitledParagraph>
                <Heading level={3}>Association Liability</Heading>
                <Paragraph>
                  This is a combined Professional Indemnity and Management Liability policy predominantly for the running of an association,
                  charity or not-for-profit organisation. It provides cover for made by directors, officers, committee members and managers
                  against legal costs and damage expenses that could arise from allegations of misconduct, breach of duty or mismanagement.
                </Paragraph>
              </TitledParagraph>
            )}
            {recommendedCovers?.includes('ContractWorks') && !businessPackRecommendedCovers?.includes('ContractWorks') && (
              <TitledParagraph>
                <Heading level={3}>Contract Works</Heading>
                <Paragraph>
                  This policy provides cover for two main sections, Material Damage and Public Liability. Contract Works is a policy
                  recommended to construction, or larger plumbing, civil, electrical companies amongst other trades based occupations that
                  have entered into contracts or have construction type projects. Material Damage provides cover for sudden, unforeseen and
                  unintended physical loss or damage such as storm, fire and water damage. Material Damage can also provide cover for
                  principal supplied materials, employee tools, mobile machinery and equipment. Public Liability provides cover for sums you
                  or the business become legally liability to pay in respect to any Personal Injury or Property Damage that happens in
                  conjunction with the course of business. A demand needs to be made by a Third Party to the business in order for a claim
                  to be triggered under this section of cover.
                </Paragraph>
              </TitledParagraph>
            )}
          </Content>
        </Page>
      )}
      {importantItemsToConsider?.length > 0 && (
        <Page dynamic formName={formName}>
          <PageTitle>Important Items to Consider </PageTitle>
          <Content>
            {importantItemsToConsider?.includes('ContractualRequirements') && (
              <TitledParagraph>
                <Heading level={3}>Contractual Requirements</Heading>
                <Paragraph>
                  When tendering for any contract, it is important to refer any insurance contract requirements to your Broker to ensure
                  adequate cover is in place.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('CyclonePreparedness') && (
              <TitledParagraph>
                <Heading level={3}>Cyclone Preparedness</Heading>
                <Paragraph>
                  It is important that your Business has a Cyclone Plan or better yet, a Business Continuity Plan in place. The Northern
                  Territory Government as well as the Northern Territory Police, Fire and Emergency Services have action plans and guides
                  available on their respective websites, or contact us for more information for Business Continuity.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('FireSafetyEquipment') && (
              <TitledParagraph>
                <Heading level={3}>Fire Safety Equipment</Heading>
                <Paragraph>
                  It is important that fire safety equipment is regularly inspected and follows all relevant legislation. Speak to an
                  authorised fire equipment contractor to ensure your Business is up to code at least annually.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('Flood') && (
              <TitledParagraph>
                <Heading level={3}>Flood</Heading>
                <Paragraph>
                  Flood cover is an optional extra that needs to be selected for cover to apply. Flood is a significant exposure can that
                  have a devastating impact to your business. Please check your location and if in a Flood prone area, we strongly advise
                  you consider insuring for flood.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('PreventativeMaintenance') && (
              <TitledParagraph>
                <Heading level={3}>Preventative Maintenance</Heading>
                <Paragraph>
                  It is important for your Business to undertake regular inspections of the exterior, interior and roof spaces to ensure the
                  property is watertight and sound. A well maintained property can lessen the likelihood of claims occurring and reduce the
                  severity, saving your Business both time and money. This is likely to reduce claims occurrence and alleviate premium
                  increases or subsequent policy actions such as imposed excesses or even declining to continue cover in the future.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('SumInsuredReviews') && (
              <TitledParagraph>
                <Heading level={3}>Sum Insured Reviews</Heading>
                <Paragraph>
                  It is important that an Insurance Sum Insured Review be conducted on a regular basis, particularly considering the current
                  rate of inflation, shortages, supply chain delay and cost of materials and labour.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('UnderInsurance') && (
              <TitledParagraph>
                <Heading level={3}>Under Insurance</Heading>
                <Paragraph>
                  It is important to understand that two of the key coverages available in your business insurance, namely; Material Damage
                  and Business Interruption operate differently than many other policies such as your home and contents policy for example.
                </Paragraph>
                <Paragraph>
                  The most notable difference is due to what is called the Average Clause, sometime referred to as Co-Insurance. This clause
                  effectively calculates the proportion of the risk you have insured for and will pay only that proportion of any valid
                  claim considering you to personally be the insurer of the other portion ‘the co-insurer’, even in a partial loss. It is
                  vitally important that you understand this, as being under insured even in very large businesses can quickly lead to
                  business failure or, at best, financial stress.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('SubcontractorsLabourHire') && (
              <TitledParagraph>
                <Heading level={3}>Subcontractors/Labour Hire</Heading>
                <Paragraph>
                  If you engage any sub-contractors or utilise labour hire workers please ensure this is declared to your Broker to ensure
                  adequate cover is in place.
                </Paragraph>
              </TitledParagraph>
            )}
            {importantItemsToConsider?.includes('ImportsExports') && (
              <TitledParagraph>
                <Heading level={3}>Imports/Exports</Heading>
                <Paragraph>
                  Please notify your Broker if you are importing or exporting any goods as additional information may be need to be provided
                  to the insurer to ensure adequate cover.
                </Paragraph>
              </TitledParagraph>
            )}
          </Content>
        </Page>
      )}
    </>
  );

  if (formName === 'strata') return <StrataUninsuredRisks />;
  if (formName === 'workerCompensation') return <WorkerCompensationUninsuredRisks />;
  if (formName === 'commercialMotor') return <CommercialMotorUninsuredRisks />;
  if (formName === 'masterRenewal') return <MasterRenewalUninsuredRisks />;

  return <StrataUninsuredRisks />;
};
